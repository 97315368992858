import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ['flash-messages'];

  connect() {
    this.element.addEventListener('click', this.preventAction.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('click', this.preventAction.bind(this));
  }

  preventAction(e) {
    if (!this._actionAllowedInPreview(e.target) && !e.target.hasAttribute('data-test-toast-close')) {
      this.flashMessagesOutlet.displayPreviewErrorMessage(e);
    }
  }

  _actionAllowedInPreview(target) {
    if(this._targetHasPreviewClass(target)) return true;

    return this._targetTagAllowed(target);
  }

  _targetHasPreviewClass(target) {
    let hasClass = false;
    let currentTarget = target;

    while (!hasClass) {
      if (currentTarget.classList.contains('js-allow-preview')) {
        hasClass = true;
        break;
      } else if (currentTarget.nodeName === 'BODY') {
        break;
      }

      currentTarget = currentTarget.parentNode;
    }

    return hasClass;
  }

  _targetTagAllowed(target) {
    const nodeName  = target.nodeName;

    return !(nodeName === 'BUTTON' ||
      (nodeName === 'INPUT' && target.type === 'submit') ||
      (nodeName === 'SPAN' && this._targetHasButtonParent(target)));
  }

  _targetHasButtonParent(target) {
    return target.parentNode.nodeName === 'BUTTON' || target.parentNode.parentNode.nodeName === 'BUTTON';
  }
}
