import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  update() {
    const selectedId = document.querySelector("input[id='paypal-demo-integrations']").value;

    this.buttonTargets.forEach(button => {
      const url = new URL(button.href);
      url.searchParams.set("integration_id", selectedId);
      button.href = url.toString();
    });
  }
}