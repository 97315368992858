import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["buttonContainer"];

  static values = {
    programId: Number,
    integrationId: Number
  }

  connect() {
    this.programPriceInput = document.querySelector("input[id='paypal-product-value']");

    if (this.hasButtonContainerTarget) {
      this._waitForPayPal(() => this._loadPayPalButtons());
    }
  }

  _waitForPayPal(callback) {
    if (window.paypal) {
      callback();
    } else {
      const observer = new MutationObserver(() => {
        if (window.paypal) {
          observer.disconnect();
          callback();
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    }
  }

  _loadPayPalButtons() {
    window.paypal.Buttons({
      createOrder: async () => {
        const response = await fetch("/paypal/orders", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            program_id: this.programIdValue,
            integration_id: this.integrationIdValue,
            amount: {
              value: this.programPriceInput.value,
              currency_code: 'USD'
            }
          })
        });
        const data = await response.json();
        return data.order.id;
      },
      onApprove: async (data) => {
        await fetch(`/paypal/orders/${data.orderID}/capture`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            integration_id: this.integrationIdValue
          })
        });
      }
    }).render(this.buttonContainerTarget);
  }
}